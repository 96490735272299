/** @jsx jsx */
import { jsx, Text } from 'theme-ui'
import { Player } from '../components/liveStream'
import queryString from 'query-string';
import { useEffect, useState } from 'react';

const StreamPlayer = (props) => {

  const [entryId, setEntryId] = useState()
  const [ksession, setKsession] = useState()

  useEffect(() => {
    const queryParams = queryString.parse(props.location.search)
    console.log('queryParams', queryParams)
    setEntryId(queryParams.entryid)
    setKsession(queryParams.ksession)
  }, [props.location])

  return (
    <Player 
      id={entryId}
      ksession={ksession}
    />
  )
}

export default StreamPlayer